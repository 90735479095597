import cookie from "js-cookie";

const ACCESS_TOKEN = "access_token";

export const setAccessToken = (accessToken) => {
  return cookie.set(ACCESS_TOKEN, accessToken);
};

export const getAccessToken = () => {
  return cookie.get(ACCESS_TOKEN);
};

export const removeAccessToken = () => {
  return cookie.remove(ACCESS_TOKEN);
};

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export function searchToObject(search) {
  return search
    .substring(1)
    .split("&")
    .reduce(function (result, value) {
      var parts = value.split("=");
      if (parts[0])
        result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      return result;
    }, {});
}
