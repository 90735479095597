import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";
import { isEmpty } from "../../utils";

const Pricing = ({ team, pricings, onSelectPricing }) => {
  return (
    <Radio.Group
      className="features__pricing"
      disabled={isEmpty(team)}
      onChange={onSelectPricing}
      defaultValue={0}
    >
      {pricings.map((item, index) => (
        <Radio key={item.type} value={index}>
          <span className="features__pricing--option">
            <span>${item.pricing}</span>
            {item.type === "annually" ? (
              <>
                <span>Billed Annually</span>
                <span>
                  {Math.round((item.pricing / 12 + Number.EPSILON) * 100) / 100}
                  /month
                </span>
              </>
            ) : (
              <span>Billed Monthly</span>
            )}
          </span>
        </Radio>
      ))}
    </Radio.Group>
  );
};

Pricing.propTypes = {
  team: PropTypes.object,
  tepricingsam: PropTypes.array,
  onSelectPricing: PropTypes.func,
};

Pricing.defaultProps = {
  onSelectPricing: (f) => f,
};

export default Pricing;
