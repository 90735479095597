import React from "react";
import PropTypes from "prop-types";

import { Select } from "antd";

const { Option } = Select;

const Teams = ({ teams, onSelectTeam, isTeamsLoading }) => {
  return (
    <Select
      className="features__select-teams"
      placeholder="Select a team to upgrade"
      style={{ width: "100%" }}
      onChange={onSelectTeam}
      loading={isTeamsLoading}
      disabled={isTeamsLoading}
    >
      {teams.map(({ ID, NAME }, index) => (
        <Option key={ID} value={index}>
          {NAME}
        </Option>
      ))}
    </Select>
  );
};

Teams.propTypes = {
  onSelectTeam: PropTypes.func,
  teams: PropTypes.array,
  isTeamsLoading: PropTypes.bool,
};

export default Teams;
