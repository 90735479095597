import api from "../../api.js";

export const handleFetchTeams = async (token) => {
  const params = new URLSearchParams();
  params.append("token", token);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("auth/get_team_by_user", params, config);

  return data;
};

export const getFinanceDetail = async (id, token) => {
  const params = new URLSearchParams();
  params.append("token", token);
  params.append("finance_detail_id", id);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("fee/get_finance", params, config);

  return data;
};
