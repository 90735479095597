import React from "react";
import { Form, Row, Col, DatePicker } from "antd";

const CardPayment = () => {
  return (
    <>
      <Form.Item label="Card Number" name="cardNumber">
        <div id="fattjs-number" style={{ height: 33 }} />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Expiration"
            name="expiration"
            rules={[
              { required: true, message: "Please input your expiration date!" },
            ]}
          >
            <DatePicker format="MM/YY" picker="month" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="CVV">
            <div id="fattjs-cvv" style={{ height: 33 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CardPayment;
