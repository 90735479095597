import React from "react";
import { useLocation, useParams } from "react-router";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import beClutchLogo from "../../assets/img/be-clutch.png";
import FMLogo from "../../assets/img/FMLogo.png";
import PCILogo from "../../assets/img/PCILogo.png";

import "./Result.scss";

const Result = ({ type }) => {
  let { pricing, name } = useParams();
  const { state } = useLocation();

  return (
    <div>
      {type === "success" ? (
        <>
          <div className="billing__success-modal--header">
            <img src={beClutchLogo} alt="logo" />
            <div>
              <div>
                <p style={{ marginBottom: 0 }}>BeClutch PLUS</p>
                <p style={{ fontSize: 15, marginBottom: 0 }}>{name}</p>
              </div>
              <p>${pricing}</p>
            </div>
          </div>

          <div className="billing__success-modal--info">
            <span>
              <CheckOutlined />
            </span>
            <p>
              <span style={{ fontSize: 24 }}>Payment success</span>
            </p>
          </div>

          <div className="billing__success-modal--contact">
            <p>A payment receipt has been sent to your email address.</p>
          </div>

          <div className="billing__success-modal--logos">
            <p>Payment is processed by:</p>

            <div>
              <img src={FMLogo} alt="logo" />
              <img src={PCILogo} alt="logo" />
            </div>
          </div>

          <div class="billing__success-modal--support">
            For support contact: support@beclutch.club
          </div>
        </>
      ) : (
        <>
          <div className="billing__error-modal--header">
            <img src={beClutchLogo} alt="logo" />
            <div>
              <p>Beclutch PLUS</p>
              <p>${pricing}</p>
            </div>
          </div>

          <div className="billing__error-modal--info">
            <span>
              <CloseOutlined />
            </span>
            <p>
              <span style={{ fontSize: 24 }}>Payment error</span>
              <br />
              Reason: {state?.errorMsg}
            </p>
          </div>

          <div className="billing__error-modal--contact">
            <p>
              Please correct errors and try again or contact beclutch support:
              <br />
              support@beclutch.club
            </p>
          </div>

          <div className="billing__error-modal--logos">
            <p>Payment is processed by:</p>

            <div>
              <img src={FMLogo} alt="logo" />
              <img src={PCILogo} alt="logo" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Result.propTypes = {};

export default Result;
