import React from "react";
import PropTypes from "prop-types";

import { Table } from "antd";

const columns = [
  {
    title: "Product/Service",
    dataIndex: "name",
    key: "name",
    render: ({ teamName, packageType, description }) => (
      <div>
        <div style={{ fontSize: 24 }}>BeClutch PLUS</div>
        <div>
          {teamName +
            " - " +
            (description
              ? description
              : packageType === "annually"
              ? "Annual Subscription"
              : "Monthly Subscription")}
        </div>
      </div>
    ),
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
  },
];

const BillingTable = ({
  teamName,
  teamId,
  pricing,
  packageType,
  description,
}) => {
  return (
    <Table
      className="billing__table"
      pagination={false}
      bordered={true}
      columns={columns}
      dataSource={[
        {
          key: teamId,
          name: { teamName, packageType, description },
          price: pricing,
          qty: 1,
          total: pricing,
        },
      ]}
      footer={() => (
        <div>
          <span style={{ fontWeight: "bold", marginRight: 20 }}>Total:</span>
          {pricing}
        </div>
      )}
    />
  );
};

BillingTable.propTypes = {
  teamName: PropTypes.string,
  teamId: PropTypes.string,
  pricing: PropTypes.string,
};

export default BillingTable;
