import "./Login.scss";

import { Button, Form, Input, message } from "antd";

import { handleLogin } from "./services";
import { setAccessToken } from "../../utils.js";
import { useHistory } from "react-router";
import { useState } from "react";
import useStore from "../../store";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const setIsLoggedIn = useStore(({ setIsLoggedIn }) => setIsLoggedIn);

  const onFinish = async (values) => {
    setLoading(true);
    const { result, msg } = await handleLogin(values);
    setLoading(false);

    if (result === false) {
      return message.error(msg);
    }

    setAccessToken(msg);
    setIsLoggedIn(true);

    return history.push("/features");
  };

  return (
    <div className="login">
      <div className="login__title">Login to BeClutch</div>
      <Form
        // wrapperCol={{ sm: { span: 8, offset: 8 } }}
        // labelCol={{ sm: { span: 8, offset: 8 } }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
        className="login__form"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="pwd"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item>
          <Button
            className="login__btn"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <div className="login__slogan">Always BeClutch!</div>
    </div>
  );
};

export default Login;
