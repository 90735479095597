import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Menu, Layout, Button, Drawer } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

import beClutchLogo from "../../assets/img/be-clutch.png";
const { Header: AntdHeader } = Layout;
const Header = () => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <AntdHeader className="header">
      <div className="header__inner">
        <img className="header__logo" src={beClutchLogo} alt="logo" />

        <Menu className="header__menu" mode="horizontal" activeKey={pathname}>
          <Menu.Item key="1">
            <a href="https://beclutch.club/">Home</a>
          </Menu.Item>
          <Menu.Item className="header__menu--item" key="/features">
            <a href="https://beclutch.club/#features">Features</a>
          </Menu.Item>
          <Menu.Item className="header__menu--item" key="3">
            <a href="https://beclutch.club/#app">App</a>
          </Menu.Item>
          <Menu.Item className="header__menu--item" key="4">
            <a href="https://beclutch.club/#contact">Contact</a>
          </Menu.Item>
          <Menu.Item className="header__menu--item" key="5">
            <a href="https://beclutch.club/support">Support</a>
          </Menu.Item>
        </Menu>

        <Button
          className="header__drawer-btn"
          onClick={showDrawer}
          type="default"
          shape="circle"
          icon={<UnorderedListOutlined />}
        />

        <Drawer
          className="header__drawer"
          placement="left"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          <Menu className="header__mobile-menu" mode="vertical" activeKey={pathname}>
            <Menu.Item key="1">
              <a href="https://beclutch.club/">Home</a>
            </Menu.Item>
            <Menu.Item key="/features">
              <a href="https://beclutch.club/#features">Features</a>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="https://beclutch.club/#app">App</a>
            </Menu.Item>
            <Menu.Item key="4">
              <a href="https://beclutch.club/#contact">Contact</a>
            </Menu.Item>
            <Menu.Item key="5">
              <a href="https://beclutch.club/support">Support</a>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </AntdHeader>
  );
};

export default Header;
