import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

const CollapseFeatures = () => {
  return (
    <>
      <div className="features__intro">
        This upgrade will give you access to these additional features:
      </div>

      <Collapse className="features__collapse" expandIconPosition="right">
        <Panel header="Unlimited Roster Members" key="1">
          <p>Add more than the number of members allowed in the free plan.</p>
        </Panel>
        <Panel header="Surveys" key="2">
          <p>
            Send out surveys to gather information about individual availability
            and preferences.
          </p>
        </Panel>
        <Panel header="Notifications" key="3">
          <p>
            Receive Notifications for items created, edited and/or canceled
            including Events, Surveys, Fees and Volunteer requests.
          </p>
        </Panel>
        <Panel header="Attendance" key="4">
          <p>
            Lets you know who and how many will be attending an event as well as
            who attended past events with Attendance Reports.
          </p>
        </Panel>
        <Panel header="Themes and Customization" key="5">
          <p>
            Set the colors and logo or picture that represents your group or
            team.
          </p>
        </Panel>
        <Panel header="Volunteers and Event Sign Ups" key="10">
          <p>
            Create volunteer lists for member sign up, reminders are sent out to
            encourage participation.
          </p>
        </Panel>
        <Panel header="Reminders" key="6">
          <p>
            Send out Reminders so that essential information will not be missed
            or forgotten.
          </p>
        </Panel>
        <Panel header="Carpool" key="7">
          <p>
            With one touch alert your group that you need a ride to an event.
          </p>
        </Panel>

        <Panel header="Alerts" key="8">
          <p>
            Inform your group or team of up to the minute and spontaneous plan
            changes.
          </p>
        </Panel>

        <Panel header="Premium Support" key="9">
          <p>Response time for assistance within 12 hours. </p>
        </Panel>
      </Collapse>
    </>
  );
};

export default CollapseFeatures;
