import React from "react";

import { Input, Form, Row, Col, Radio } from "antd";

const BankPayment = (props) => {
  return (
    <>
      <Form.Item label="Bank name" name="bank_name">
        <Input />
      </Form.Item>

      <Form.Item
        label="Account type"
        name="bank_type"
        rules={[{ required: true }]}
      >
        <Radio.Group
          name="bank_type"
          className="billing__form--select-bank-types"
        >
          <Radio value="checking">Checking</Radio>
          <Radio value="savings">Saving</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Bank holder type"
        name="bank_holder_type"
        rules={[{ required: true }]}
      >
        <Radio.Group
          name="bank_holder_type"
          className="billing__form--select-bank-types"
        >
          <Radio value="personal">Personal</Radio>
          <Radio value="business">Business</Radio>
        </Radio.Group>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Routing number"
            name="bank_routing"
            rules={[
              { required: true, message: "Please input your routing number!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Account number"
            name="bank_account"
            rules={[
              { required: true, message: "Please input your account number!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

BankPayment.propTypes = {};

export default BankPayment;
