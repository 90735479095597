import React from "react";

import BillingForm from "./BillingForm";
import BillingTable from "./BillingTable";

import "./Billing.scss";

const Billing = ({
  teamName,
  teamId,
  pricing,
  packageType,
  description,
  financeDetailId,
}) => {
  return (
    <div className="billing">
      <BillingTable
        teamId={teamId}
        teamName={teamName}
        pricing={pricing}
        packageType={packageType}
        description={description}
      />
      <BillingForm
        teamId={teamId}
        teamName={teamName}
        pricing={pricing}
        packageType={packageType}
        financeDetailId={financeDetailId}
      />
    </div>
  );
};

export default Billing;
