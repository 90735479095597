import api from "../../api.js";

export const handleLogin = async ({ email, pwd }) => {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("pwd", pwd);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("auth/login", params, config);

  return data;
};
